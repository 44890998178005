import React, { memo as Memo } from 'react'
import { Text, Button ,Title} from 'components/common'
import ErrorPageStyle from 'styles/pages/ErrorPageStyle'

const NotFound = Memo((props) => {
	return (
		<ErrorPageStyle className='FlexBoxColumn AlignMiddle AlignCenter'>
			<Text tag={'h3'} className={'title PolySansNeutralRegular grey'} text={'PageNotFound'} />
			
			<Button link={'/'} className={'purple'}>
				<Title type={'btn'} text={['BackToHome']} fontSize={'pL'} className={'whiteDark'} />
			</Button>
		</ErrorPageStyle>
	)
})

export default NotFound
